/* CSS Reset */
*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

ul[role='list'],
ol[role='list'] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
}


img,
picture {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}




:root {
  /* Color Palette */
  /* Add subtle color improvements */
  --brand-color: #021c43;
  --primary-green: #22c55e;
  --hover-green: #16a34a;
  --light-green: #ddffed;
  /* Slightly lighter for more subtlety */
  --white: #ffffff;
  --gray-100: #f9fafb;
  --gray-200: #e5e7eb;
  --gray-800: #1f2937;

  --accent-color: #FFA500;
  --text-dark: #333333;
  --text-medium: #666666;
  --text-light: #999999;
  --background-light: #F8F9FA;
  --white: #FFFFFF;
  --error: #FF4136;
  --success: #2ECC40;
  --warning: #FF851B;

  /* Typography */
  --font-primary: 'Poppins', sans-serif;
  --font-secondary: 'Inter', sans-serif;

  /* Font Sizes */
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-base: 1rem;
  --font-size-lg: 1.125rem;
  --font-size-xl: 1.25rem;
  --font-size-2xl: 1.5rem;
  --font-size-3xl: 1.875rem;
  --font-size-4xl: 2.25rem;

  /* Spacing */
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 2rem;
  --spacing-2xl: 3rem;

  /* Border Radius */
  --border-radius-sm: 4px;
  --border-radius-md: 8px;
  --border-radius-lg: 16px;

  /* Box Shadows */
  --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 10px 15px rgba(0, 0, 0, 0.1);

  /* Transitions */
  --transition-fast: 0.2s ease;
  --transition-normal: 0.3s ease;
  --transition-slow: 0.5s ease;

  /* Z-index */
  --z-index-dropdown: 1000;
  --z-index-sticky: 1020;
  --z-index-fixed: 1030;
  --z-index-modal-backdrop: 1040;
  --z-index-modal: 1050;
  --z-index-popover: 1060;
  --z-index-tooltip: 1070;
}

/* Global Styles */
body {
  font-family: var(--font-secondary);
  font-size: var(--font-size-base);
  line-height: 1.5;
  color: var(--text-dark);
  background-color: var(--white);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-primary);
  font-weight: 600;
  color: var(--primary-dark);
}

a {
  color: var(--primary-color);
  text-decoration: none;
  transition: color var(--transition-normal);
}

a:hover {
  color: var(--primary-dark);
}

.btn {
  display: inline-block;
  padding: var(--spacing-sm) var(--spacing-md);
  font-family: var(--font-primary);
  font-weight: 600;
  font-size: var(--font-size-base);
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  transition: background-color var(--transition-normal), transform var(--transition-normal);
}

.btn-primary {
  background-color: var(--primary-color);
  color: var(--white);
}

.btn-primary:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
}

.btn-secondary {
  background-color: var(--secondary-color);
  color: var(--white);
}

.btn-secondary:hover {
  background-color: var(--accent-color);
  transform: translateY(-2px);
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--spacing-md);
}

/* Utility Classes */
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.mt-1 {
  margin-top: var(--spacing-xs);
}

.mt-2 {
  margin-top: var(--spacing-sm);
}

.mt-3 {
  margin-top: var(--spacing-md);
}

.mt-4 {
  margin-top: var(--spacing-lg);
}

.mt-5 {
  margin-top: var(--spacing-xl);
}

.mb-1 {
  margin-bottom: var(--spacing-xs);
}

.mb-2 {
  margin-bottom: var(--spacing-sm);
}

.mb-3 {
  margin-bottom: var(--spacing-md);
}

.mb-4 {
  margin-bottom: var(--spacing-lg);
}

.mb-5 {
  margin-bottom: var(--spacing-xl);
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.rounded {
  border-radius: var(--border-radius-md);
}

.rounded-lg {
  border-radius: var(--border-radius-lg);
}

.shadow-sm {
  box-shadow: var(--shadow-sm);
}

.shadow-md {
  box-shadow: var(--shadow-md);
}

.shadow-lg {
  box-shadow: var(--shadow-lg);
}

@media (max-width: 768px) {
  :root {
    --font-size-4xl: 2rem;
    --font-size-3xl: 1.75rem;
    --font-size-2xl: 1.5rem;
    --font-size-xl: 1.25rem;
    --font-size-lg: 1.125rem;
  }
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
  :root {
    --primary-color: #4A90E2;
    --primary-dark: #2171C7;
    --primary-light: #7EADEA;
    --text-dark: #E0E0E0;
    --text-medium: #B0B0B0;
    --text-light: #808080;
    --background-light: #2C2C2C;
    --white: #1A1A1A;
  }

  body {
    background-color: var(--white);
    color: var(--text-dark);
  }

  a {
    color: var(--primary-light);
  }

  a:hover {
    color: var(--primary-color);
  }
}


.btn {
  display: inline-block;
  padding: var(--spacing-sm) var(--spacing-md);
  font-family: var(--font-primary);
  font-weight: 600;
  font-size: var(--font-size-base);
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  transition: background-color var(--transition-normal), transform var(--transition-normal), box-shadow var(--transition-normal);
}

.btn-primary {
  background-color: var(--primary-color);
  color: var(--white);
}

.btn-primary:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

.btn-secondary {
  background-color: var(--secondary-color);
  color: var(--white);
}

.btn-secondary:hover {
  background-color: var(--accent-color);
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

.btn-outline {
  background-color: transparent;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
}

.btn-outline:hover {
  background-color: var(--primary-light);
  color: var(--primary-dark);
  transform: translateY(-2px);
  box-shadow: var(--shadow-sm);
}

@media (max-width: 768px) {
  .btn {
    padding: var(--spacing-xs) var(--spacing-sm);
    font-size: var(--font-size-sm);
  }
}