.servicesContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 1rem;
}

.servicesTitle {
  text-align: center;
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 3rem;
  position: relative;
}

.servicesTitle::after {
  content: '';
  display: block;
  width: 50px;
  height: 3px;
  background-color: #22c55e;
  margin: 1rem auto 0;
}

.servicesList {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

.serviceCard {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.serviceCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.serviceImageWrapper {
  position: relative;
  width: 100%;
  padding-top: 66.67%; /* 3:2 aspect ratio */
  overflow: hidden;
}

.serviceImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.3s ease;
}

.serviceCard:hover .serviceImage {
  transform: scale(1.05);
}

.serviceContent {
  padding: 1.5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.serviceTitle {
  font-size: 1.4rem;
  color: #333;
  margin-bottom: 0.7rem;
}

.serviceDescription {
  font-size: 0.95rem;
  color: #666;
  line-height: 1.6;
  margin-bottom: 1rem;
  flex-grow: 1;
}

.readMoreBtn {
  background: none;
  border: none;
  color: #22c55e;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  text-align: left;
  transition: color 0.3s ease;
}

.readMoreBtn:hover {
  color: #16a34a;
  text-decoration: underline;
}

.serviceFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}

.serviceButton {
  text-decoration: none;
  padding: 0.6rem 1rem;
  border-radius: 5px;
  font-size: 0.9rem;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.bookNowButton {
  background-color: #22c55e;
  color: white;
}

.bookNowButton:hover {
  background-color: #16a34a;
  transform: translateY(-2px);
}

.learnMoreButton {
  background-color: #021c43;
  color: white;
}

.learnMoreButton:hover {
  background-color: #032a64;
  transform: translateY(-2px);
}

.noServices {
  text-align: center;
  font-size: 1.2rem;
  color: #666;
  margin-top: 3rem;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 8px;
}

@media (min-width: 576px) {
  .servicesList {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) {
  .serviceTitle {
    font-size: 1.6rem;
  }

  .serviceDescription {
    font-size: 1rem;
  }

  .serviceButton {
    font-size: 1rem;
    padding: 0.7rem 1.2rem;
  }
}

@media (min-width: 992px) {
  .servicesList {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-auto-flow: column;
  }
}
