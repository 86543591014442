.navigationButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 0.25rem;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.primaryButton {
  background-color: var(--primary-green);
  color: var(--white);
  border: none;
}

.primaryButton:hover {
  background-color: var(--hover-green);
}

.secondaryButton {
  background-color: var(--white);
  color: var(--primary-green);
  border: 1px solid var(--primary-green);
}

.secondaryButton:hover {
  background-color: var(--light-green);
}