.bookingForm {
  max-width: 800px;
  margin: 120px auto 0; /* Increased top margin to prevent overlap with header */
  padding: 40px;
  background-color: #ffffff;
  border-radius: 16px;
  font-family: 'Roboto', Arial, sans-serif;
}

.bookingTitle {
  text-align: center;
  color: #021c43;
  margin-bottom: 40px;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.progressBar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  position: relative;
  padding: 0 30px;
}

.progressBar::before {
  content: '';
  position: absolute;
  top: 18px;
  left: 40px;
  right: 40px;
  height: 3px;
  background-color: #e0e0e0;
  z-index: 1;
}

.progressStepContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  flex: 1;
  max-width: 120px;
}

.progressStep {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #fff;
  font-size: 18px;
  transition: all 0.4s ease;
  margin-bottom: 12px;
  border: 3px solid #fff;
}

.progressStep.active {
  background-color: #22c55e;
  transform: scale(1.15);
}

.progressLabel {
  font-size: 14px;
  color: #4a5568;
  text-align: center;
  line-height: 1.4;
  transition: all 0.3s ease;
  font-weight: 500;
}

.progressStepContainer.active .progressLabel {
  font-weight: 600;
  color: #22c55e;
}

.stepContent {
  animation: fadeIn 0.6s ease-in-out;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 30px;
  transition: all 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.errorMessage {
  color: #dc2626;
  font-size: 14px;
  margin-top: 10px;
  padding: 10px;
  background-color: #fef2f2;
  border: 1px solid #dc2626;
  border-radius: 4px;
  text-align: center;
}

@media (max-width: 768px) {
  .bookingForm {
    margin-top: 100px; /* Adjusted for smaller screens */
    padding: 30px 20px;
  }

  .bookingTitle {
    font-size: 28px;
    margin-bottom: 30px;
  }

  .progressBar {
    padding: 0 10px;
    margin-bottom: 40px;
  }

  .progressBar::before {
    left: 20px;
    right: 20px;
  }

  .progressStepContainer {
    max-width: 90px;
  }

  .progressStep {
    width: 36px;
    height: 36px;
    font-size: 16px;
  }

  .progressLabel {
    font-size: 12px;
  }

  .stepContent {
    padding: 25px;
  }
}

.nextButton {
  background-color: #22c55e;
  color: white;
  padding: 14px 28px;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  box-shadow: 0 4px 6px rgba(34, 197, 94, 0.2);
  width: 100%;
  max-width: 300px;
  margin: 20px auto 0;
  display: block;
}

.nextButton:hover {
  background-color: #16a34a;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(22, 163, 74, 0.3);
}

.nextButton:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(22, 163, 74, 0.2);
}

.nextButton:disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
  box-shadow: none;
  transform: none;
}

@media (max-width: 480px) {
  .bookingForm {
    margin-top: 80px; /* Further adjusted for mobile screens */
    padding: 20px 15px;
  }

  .bookingTitle {
    font-size: 24px;
    margin-bottom: 25px;
  }

  .nextButton {
    font-size: 16px;
    padding: 12px 24px;
  }

  .progressBar {
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  .progressBar::before {
    display: none;
  }

  .progressStepContainer {
    margin-bottom: 10px;
  }

  .progressLabel {
    font-size: 11px;
  }

  .stepContent {
    padding: 20px;
  }

  .grid {
    grid-template-columns: 1fr;
  }

  .buttonGroup {
    flex-direction: column;
    gap: 15px;
  }

  .prevButton,
  .nextButton {
    width: 100%;
  }
}
