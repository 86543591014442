:root {
  --brand-color: #021c43;
  --primary-green: #22c55e;
  --hover-green: #16a34a;
  --light-green: #ddffed;
  --white: #ffffff;
  --gray-100: #f9fafb;
  --gray-200: #e5e7eb;
  --gray-800: #1f2937;
}

.serviceCustomization {
  margin-bottom: 2rem;
}

.stepIcon {
  color: var(--primary-green);
  font-size: 1.5rem;
  margin-right: 0.5rem;
  vertical-align: middle;
}

.serviceTabs {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
  overflow-x: auto;
  padding-bottom: 0.5rem;
}

.serviceTab {
  background-color: var(--gray-200);
  border: none;
  border-radius: 0.5rem;
  color: var(--gray-800);
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  margin-right: 0.5rem;
  padding: 0.75rem 1.5rem;
  transition: all 0.3s ease;
}

.serviceTab:hover {
  background-color: var(--light-green);
}

.activeTab {
  background-color: var(--primary-green);
  color: var(--white);
}

.incompleteTab {
  border: 2px solid #f87171;
}

.serviceDetails {
  background-color: var(--gray-100);
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
}

.serviceDetails h3 {
  color: var(--brand-color);
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.serviceDetails p {
  color: var(--gray-800);
  font-size: 1rem;
}

.subServicesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

.subServiceCard {
  background-color: var(--white);
  border: 2px solid var(--gray-200);
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  transition: all 0.3s ease;
}

.subServiceCard:hover {
  border-color: var(--primary-green);
}

.subServiceCard.selected {
  background-color: var(--light-green);
  border-color: var(--primary-green);
}

.subServiceInfo {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.subServiceIcon {
  color: var(--primary-green);
  font-size: 2rem;
  margin-right: 0.75rem;
  min-width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subServiceName {
  font-size: 1rem;
  font-weight: 600;
  color: var(--gray-800);
}

.subServiceDescription {
  color: var(--gray-800);
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.checkboxContainer {
  display: flex;
  justify-content: flex-end;
}

.checkIcon {
  color: var(--primary-green);
  font-size: 1.25rem;
}
