.testimonials {
  padding: 3rem 0;
  overflow: hidden;
  width: 100%;
  background-color: #f8f9fa;

}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.testimonialTitle {
  text-align: center;
  margin-bottom: 2rem;
}

.testimonialScroll {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.scrollContent {
  display: flex;
  animation: scroll var(--animation-duration) linear infinite;
}

.testimonialScroll.expanded {
  overflow: visible;
}

.testimonialScroll.expanded .scrollContent {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  animation: none;
}

.testimonialCard {
  flex: 0 0 280px;
  margin: 0 1rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-width: 280px;
  max-width: 100%;
}

.testimonialScroll.expanded .testimonialCard {
  flex: 1 1 auto;
  margin: 0;
  min-width: 0;
  max-width: none;
  height: auto;
  min-height: 300px;
  overflow: auto;
}

.testimonialText {
  font-style: italic;
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.6;
  flex-grow: 1;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.testimonialFooter {
  display: flex;
  flex-direction: column;
  margin-top: auto;
}

.testimonialRating {
  display: flex;
  margin-bottom: 0.5rem;
}

.starFilled {
  color: #ffc107;
  font-size: 1.2rem;
}

.starEmpty {
  color: #e4e5e9;
  font-size: 1.2rem;
}

.testimonialAuthor {
  display: flex;
  flex-direction: column;
}

.authorName {
  font-weight: bold;
  font-size: 1rem;
  margin: 0;
}

.authorArea {
  font-size: 0.9rem;
  color: #6c757d;
  margin: 0;
}

.controlsContainer {
  text-align: center;
  margin-top: 2rem;
}

.controlButton {
  background-color: var(--brand-color);
  color: #ffffff;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.controlButton:hover {
  background-color: var(--primary-green);
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-1 * var(--scroll-width) / 2));
  }
}

@media (max-width: 768px) {
  .testimonialCard {
    flex: 0 0 calc(100% - 2rem);
    margin: 0 1rem;
    max-width: none;
  }

  .testimonialScroll.expanded .scrollContent {
    grid-template-columns: 1fr;
  }

  .testimonialText {
    font-size: 0.9rem;
  }

  .starFilled,
  .starEmpty {
    font-size: 1rem;
  }

  .authorName {
    font-size: 0.9rem;
  }

  .authorArea {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .testimonialCard {
    margin: 0 0.5rem;
    padding: 1rem;
  }

  .testimonialText {
    font-size: 0.85rem;
  }

  .starFilled,
  .starEmpty {
    font-size: 0.9rem;
  }

  .authorName {
    font-size: 0.85rem;
  }

  .authorArea {
    font-size: 0.75rem;
  }
}