.heroSection {
  position: relative;
  width: 100%;
  height: calc(100vh - 60px); /* Subtract header height */
  min-height: 400px; /* Ensure minimum height on smaller screens */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(0, 123, 255, 0.7), rgba(40, 167, 69, 0.5));
  backdrop-filter: blur(2px);
}

.container {
  width: 100%;
  max-width: 1200px;
  padding: 4rem 2rem;
  z-index: 10;
}

.content {
  color: #ffffff;
  text-align: center;
}

.title {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.whiteTitle {
  color: #ffffff;
}

.subtitle {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.description {
  font-size: 1.1rem;
  margin-bottom: 2rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.primaryButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 8px;
  transition: all 300ms ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #ffffff;
  background-color: #28a745;
  border: none;
  cursor: pointer;
}

.pulsingButton {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.7);
  }

  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(40, 167, 69, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0);
  }
}

.primaryButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  background-color: #218838;
}

.largeButton {
  font-size: 1.3rem;
  padding: 1.2rem 2.5rem;
}

@media (max-width: 768px) {
  .heroSection {
    height: auto;
    min-height: 60vh;
  }

  .container {
    padding: 1rem 0.75rem;
  }

  .title {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1rem;
  }

  .description {
    font-size: 0.85rem;
    max-width: 100%;
  }

  .primaryButton {
    padding: 0.5rem 1rem;
    font-size: 0.85rem;
  }

  .largeButton {
    font-size: 0.95rem;
    padding: 0.7rem 1.4rem;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1rem;
  }

  .description {
    font-size: 0.9rem;
  }

  .primaryButton {
    padding: 0.6rem 1.2rem;
    font-size: 0.9rem;
  }

  .largeButton {
    font-size: 1rem;
    padding: 0.8rem 1.6rem;
  }
}