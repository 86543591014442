.headerWrapper {
  width: 100%;
  background-color: rgba(2, 28, 67, 0.95);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 998;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  transition: all 0.3s ease-in-out;
}

.headerWrapper.sticky {
  background-color: rgba(2, 28, 67, 0.98);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 var(--spacing-md);
  width: 100%;
  box-sizing: border-box;
}

.headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  position: relative;
}

.leftSection {
  display: flex;
  align-items: center;
}

.logoTitleContainer {
  display: flex;
  align-items: center;
}

.logoPlaceholder {
  width: 40px;
  height: 40px;
  background-color: #f0f0f0;
  margin-right: 10px;
}

.titleLink {
  text-decoration: none;
}

.title {
  font-size: 1.5rem;
  color: #ffffff;
  margin: 0;
  font-weight: bold;
}

.rightSection {
  display: flex;
  align-items: center;
}

.navigation {
  display: flex;
  align-items: center;
}

.navList {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navItem {
  margin: 0 10px;
}

.navLink {
  color: #ffffff;
  text-decoration: none;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.navLink:hover,
.navLink.active {
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
}

.mobileBookNow {
  display: none;
}

.buttonPlaceholder {
  width: 100px;
  height: 40px;
  background-color: #f0f0f0;
}

.navbarToggler {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
}

.burgerIcon {
  display: block;
  width: 25px;
  height: 3px;
  background-color: #ffffff;
  position: relative;
  transition: background-color 0.3s ease;
}

.burgerIcon::before,
.burgerIcon::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 3px;
  background-color: #ffffff;
  transition: all 0.3s ease;
}

.burgerIcon::before {
  top: -8px;
}

.burgerIcon::after {
  bottom: -8px;
}

.navbarToggler.active .burgerIcon {
  background-color: transparent;
}

.navbarToggler.active .burgerIcon::before {
  top: 0;
  transform: rotate(45deg);
}

.navbarToggler.active .burgerIcon::after {
  bottom: 0;
  transform: rotate(-45deg);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 997;
}

@media (max-width: 767px) {
  .headerWrapper {
    padding: 5px 0;
  }

  .headerContent {
    height: 50px;
  }

  .title {
    font-size: 1.2rem;
  }

  .navbarToggler {
    display: block;
  }

  .navigation {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #021c43;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 20px;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }

  .navigation.open {
    transform: translateX(0);
  }

  .navList {
    flex-direction: column;
    align-items: center;
  }

  .navItem {
    margin: 10px 0;
  }

  .mobileBookNow {
    display: block;
    margin-top: 20px;
  }

  .desktopBookNow {
    display: none;
  }
}
