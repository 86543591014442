.newsletter {
  background-color: #021c43;
  padding: 4rem 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
}

.icon {
  font-size: 3rem;
  color: #ffffff;
  margin-bottom: 1.5rem;
}

.title {
  position: relative;
  padding-bottom: 0.5rem;
  margin-bottom: 1.5rem;
}

.title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 3px;
  background-color: #4CAF50;
}

.title h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #ffffff;
  margin: 0 0 0.5rem 0;
}

.subtitle {
  font-size: 1.2rem;
  color: #e0e0e0;
  font-weight: normal;
}

.description {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #e0e0e0;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  max-width: 600px;
  margin: 0 auto;
}

.inputWrapper {
  grid-column: span 2;
  position: relative;
}

.inputIcon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-color-light);
  font-size: 1.2rem;
}

.checkboxWrapper {
  grid-column: span 2;
}

.button {
  grid-column: span 2;
}

.input,
.select {
  width: 100%;
  padding: 0.75rem 1rem 0.75rem 2.5rem;
  font-size: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.input:focus,
.select:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(var(--primary-color-rgb), 0.1);
}

.select {
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23333" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%;
  background-size: 1.2em auto;
  padding-right: 2.5rem;
}

.button {
  background-color: #28a745;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
}

.button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  background-color: #218838;
}

.button:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 1rem 0;
}

.successMessage {
  font-size: 1.2rem;
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  border-radius: 8px;
  padding: 1.5rem;
  margin-top: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  animation: slideIn 0.5s ease-out;
}

.successMessage::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(to right, #28a745, #5cb85c);
}

.successIcon {
  font-size: 2.5rem;
  color: #28a745;
  margin-bottom: 1rem;
  animation: scaleIn 0.5s ease-out;
}

.successMessage p {
  margin: 0.5rem 0;
}

.subscribers {
  font-size: 1rem;
  color: var(--text-color);
  margin-bottom: 1.5rem;
}

.testimonial {
  font-style: italic;
  color: var(--text-color);
  max-width: 700px;
  margin: 1.5rem auto;
  position: relative;
  padding: 1.5rem 2rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.testimonial::before,
.testimonial::after {
  content: '"';
  font-size: 3rem;
  color: var(--primary-color);
  opacity: 0.2;
  position: absolute;
  top: -10px;
}

.testimonial::before {
  left: 10px;
}

.testimonial::after {
  right: 10px;
}

.blueTestimonial {
  color: #021c43;
}

.urgency {
  font-weight: bold;
  color: var(--primary-color);
  margin-top: 1.5rem;
  font-size: 1.1rem;
  animation: bounce 2s ease-out infinite;
}

.arrow {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid #28a745;
  margin: 10px auto;
  animation: bounce 2s ease-out infinite;
}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-15px);
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.privacy {
  font-size: 0.9rem;
  color: #b0b0b0;
  margin-top: 1.5rem;
}

.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .newsletter {
    padding: 3rem 1.5rem;
  }

  .title h2 {
    font-size: 2rem;
  }

  .description {
    font-size: 1.1rem;
  }

  .form {
    padding: 1.5rem;
  }

  .successMessage {
    font-size: 1.1rem;
    padding: 1.25rem;
  }
}