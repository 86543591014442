.formSection {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.formTitle {
  font-size: 2.5rem;
  color: #021c43;
  margin-bottom: 2rem;
  text-align: center;
}

.form {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  width: 100%;
}

.form:hover {
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
}

.formGroup {
  margin-bottom: 1.5rem;
  position: relative;
}

.label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #1f2937;
  font-size: 1rem;
}

.input,
.textarea {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid #e5e7eb;
  border-radius: 6px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background-color: #f9fafb;
}

.input:focus,
.textarea:focus {
  outline: none;
  border-color: #22c55e;
  box-shadow: 0 0 0 3px rgba(34, 197, 94, 0.1);
  background-color: #ffffff;
}

.textarea {
  min-height: 120px;
  resize: vertical;
}

.submitButton {
  background-color: #22c55e;
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 4px 6px rgba(34, 197, 94, 0.2);
}

.submitButton:hover {
  background-color: #16a34a;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(34, 197, 94, 0.3);
}

.submitButton:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(34, 197, 94, 0.2);
}

.submitButton:disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.errorMessage {
  color: #dc2626;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  font-weight: 500;
}

.errorInput {
  border-color: #dc2626;
}

.successMessage,
.errorMessage {
  margin-top: 1.5rem;
  padding: 1rem;
  border-radius: 6px;
  text-align: center;
}

.successMessage {
  background-color: #ddffed;
  color: #16a34a;
  border: 1px solid #22c55e;
}

.errorMessage {
  background-color: #fee2e2;
  color: #b91c1c;
  border: 1px solid #dc2626;
}

.successMessage h3,
.errorMessage h3 {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}

.spinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  margin-right: 8px;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .formSection {
    padding: 10px;
  }
  
  .form {
    padding: 1.5rem;
  }
}
