/* Import typography and design system styles */
@import 'typography.css';
@import 'designSystem.css';

/* Add any additional global styles that are not in designSystem.css */
/* For example: */

/* Styles for specific layout or component needs */
.main-content {
  min-height: calc(100vh - 60px); /* Adjust based on your header/footer height */
}

/* Any custom animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-in {
  animation: fadeIn 0.5s ease-in;
}

/* Add more custom global styles as needed */