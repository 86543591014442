:root {
  /* Add subtle color improvements */
  --brand-color: #021c43;
  --primary-green: #22c55e;
  --hover-green: #16a34a;
  --light-green: #ddffed;
  /* Slightly lighter for more subtlety */
  --white: #ffffff;
  --gray-100: #f9fafb;
  --gray-200: #e5e7eb;
  --gray-800: #1f2937;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

@media (max-width: 768px) {
  .container {
    padding: 0 15px;
  }
}