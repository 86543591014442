.ctaSection {
  background-color: #e0f2ff;
  width: 100%;
  text-align: center;
}

.ctaContainer {
  max-width: 1200px; /* Adjust this value to match other containers on the site */
  width: 100%;
  padding: 4rem 2rem;
  margin: 0 auto;
}

.ctaTitle {
  font-size: 2.5rem;
  color: #021c43;
  margin-bottom: 1rem;
}

.ctaDescription {
  font-size: 1.2rem;
  color: #1f2937;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.ctaButton {
  display: inline-block;
  background-color: #22c55e;
  color: white;
  text-decoration: none;
  padding: 1rem 2rem;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: 600;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 4px 6px rgba(34, 197, 94, 0.2);
}

.ctaButton:hover {
  background-color: #16a34a;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(34, 197, 94, 0.3);
}

.ctaButton:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(34, 197, 94, 0.2);
}