:root {
  /* Add subtle color improvements */
  --brand-color: #021c43;
  --primary-green: #22c55e;
  --hover-green: #16a34a;
  --light-green: #ddffed;
  /* Slightly lighter for more subtlety */
  --white: #ffffff;
  --gray-100: #f9fafb;
  --gray-200: #e5e7eb;
  --gray-800: #1f2937;
}

.bookingPage {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.pageTitle {
  font-size: 36px;
  color: var(--brand-color);
  text-align: center;
  margin-bottom: 40px;
  text-transform: uppercase;
  font-weight: bold;
}

.stepContainer {
  margin-bottom: 40px;
}

.stepTitle {
  font-size: 24px;
  color: var(--brand-color);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.stepIcon {
  color: var(--primary-green);
  font-size: 24px;
  margin-right: 10px;
}

.serviceGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 40px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.serviceCard {
  background-color: var(--white);
  border: 2px solid transparent;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.serviceCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-color: var(--primary-green);
  /* Highlight on hover */
}

.serviceCard.selected {
  background-color: var(--light-green);
  border-color: var(--primary-green);
}

.emptyCard {
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: default;
}

.emptyCard:hover {
  transform: none;
  box-shadow: none;
  border-color: transparent;
}

.serviceIcon {
  color: var(--primary-green);
  font-size: 3rem;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: var(--light-green);
  transition: all 0.3s ease;
}

.serviceCard:hover .serviceIcon {
  background-color: var(--primary-green);
  color: var(--white);
}

.serviceName {
  font-size: 18px;
  font-weight: bold;
  color: var(--gray-800);
}

.errorMessage {
  color: #ef4444;
  font-size: 14px;
  margin-top: 10px;
}

/* Responsive improvements */
@media (max-width: 768px) {
  .bookingPage {
    padding: 10px;
  }

  .pageTitle {
    font-size: 28px;
  }

  .stepTitle {
    font-size: 20px;
  }

  .stepIcon {
    font-size: 20px;
  }

  .serviceGrid {
    grid-template-columns: 1fr;
  }

  .serviceCard {
    height: auto;
    padding: 15px;
  }

  .serviceIcon {
    font-size: 2.5rem;
    height: 60px;
    width: 60px;
  }
}