@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Poppins:wght@600;700&display=swap');

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: var(--font-primary);
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0.5em;
  color: var(--text-dark);
}

h1, .h1 { font-size: 2.5rem; }
h2, .h2 { font-size: 2rem; }
h3, .h3 { font-size: 1.75rem; }
h4, .h4 { font-size: 1.5rem; }
h5, .h5 { font-size: 1.25rem; }
h6, .h6 { font-size: 1rem; }

.body-large {
  font-size: 1.125rem;
  line-height: 1.6;
}

.body {
  font-size: 1rem;
  line-height: 1.5;
}

.body-small {
  font-size: 0.875rem;
  line-height: 1.4;
}

/* Responsive typography */
@media (max-width: 768px) {
  h1, .h1 { font-size: 2rem; }
  h2, .h2 { font-size: 1.75rem; }
  h3, .h3 { font-size: 1.5rem; }
  h4, .h4 { font-size: 1.25rem; }
  h5, .h5 { font-size: 1.125rem; }
  h6, .h6 { font-size: 1rem; }

  .body-large { font-size: 1rem; }
  .body { font-size: 0.9375rem; }
  .body-small { font-size: 0.8125rem; }
}

/* Fluid typography for larger screens */
@media (min-width: 1200px) {
  html {
    font-size: calc(16px + 0.390625vw);
  }
}

/* Focus styles for better accessibility */
a:focus,
button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
}

