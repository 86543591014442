.services {
  width: 100%;
  padding: 2rem 0;
}

.sectionTitle {
  font-size: 2.5rem;
  text-align: center;
  color: var(--brand-color);
  margin-bottom: 2rem;
}

.servicesContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.serviceCard {
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.serviceCard:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.serviceIcon {
  font-size: 3rem;
  color: var(--primary-green);
  margin-bottom: 1rem;
}

.serviceTitle {
  font-size: 1.5rem;
  color: var(--brand-color);
  margin-bottom: 1rem;
}

.serviceDescription {
  font-size: 1rem;
  color: var(--gray-800);
  margin-bottom: 1.5rem;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.learnMoreButton,
.bookNowButton {
  padding: 0.75rem 1.5rem;
  border-radius: 30px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
}

.learnMoreButton {
  background-color: transparent;
  color: var(--primary-green);
  border: 2px solid var(--primary-green);
}

.learnMoreButton:hover {
  background-color: var(--light-green);
  transform: scale(1.05);
}

.bookNowButton {
  background-color: var(--primary-green);
  color: var(--white);
  border: none;
}

.bookNowButton:hover {
  background-color: var(--hover-green);
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .servicesContainer {
    grid-template-columns: 1fr;
  }
}