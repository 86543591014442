@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(34, 197, 94, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(34, 197, 94, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(34, 197, 94, 0);
  }
}

.bookNowButton {
  background-color: #22c55e;
  padding: 8px 16px; /* Reduced padding */
  border-radius: 5px;
  color: white;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease;
  animation: pulse 2s infinite;
  font-weight: 600;
  font-size: 0.9rem; /* Reduced font size */
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.5px;
}

.bookNowButton:hover {
  background-color: #16a34a;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.buttonText {
  margin-right: 5px;
}

.bookNowArrow {
  width: 16px; /* Reduced size */
  height: 16px; /* Reduced size */
  transition: transform 0.3s ease;
}

.bookNowButton:hover .bookNowArrow {
  transform: translateX(3px);
}

@media (max-width: 767px) {
  .bookNowButton {
    padding: 6px 12px; /* Further reduced padding for mobile */
    font-size: 0.8rem; /* Further reduced font size for mobile */
  }

  .bookNowArrow {
    width: 14px; /* Further reduced size for mobile */
    height: 14px; /* Further reduced size for mobile */
  }
}