:root {
  --brand-color: #021c43;
  --primary-green: #22c55e;
  --hover-green: #16a34a;
  --light-green: #ddffed;
  --white: #ffffff;
  --gray-100: #f9fafb;
  --gray-200: #e5e7eb;
  --gray-800: #1f2937;
}

.form {
  margin-bottom: 2rem;
}

.formGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}

.inputGroup {
  display: flex;
  flex-direction: column;
}

.label {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: var(--gray-800);
}

.label svg {
  margin-right: 0.5rem;
  color: var(--primary-green);
}

.input {
  padding: 0.75rem;
  border: 1px solid var(--gray-200);
  border-radius: 0.25rem;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.input:focus {
  outline: none;
  border-color: var(--primary-green);
}

.inputError {
  border-color: #f87171;
}

.errorMessage {
  color: #f87171;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.subheading {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--gray-800);
  margin: 2rem 0 1rem;
}