:root {
  --brand-color: #021c43;
  --primary-green: #22c55e;
  --hover-green: #16a34a;
  --light-green: #ddffed;
  --white: #ffffff;
  --gray-100: #f9fafb;
  --gray-200: #e5e7eb;
  --gray-300: #d1d5db;
  --gray-400: #9ca3af;
  --gray-800: #1f2937;
  --highlight-yellow: #fef08a;
}

.summaryContainer {
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.stepTitle {
  font-size: 2rem;
  color: var(--brand-color);
  margin-bottom: 2rem;
  text-align: center;
}

.summarySection {
  margin-bottom: 2rem;
  padding: 1.5rem;
  background-color: var(--gray-100);
  border-radius: 8px;
}

.sectionTitle {
  font-size: 1.5rem;
  color: var(--brand-color);
  margin-bottom: 1rem;
  border-bottom: 2px solid var(--primary-green);
  padding-bottom: 0.5rem;
}

.serviceItem {
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.serviceItem h4 {
  font-size: 1.2rem;
  color: var(--brand-color);
  margin-bottom: 0.5rem;
}

.serviceItem p {
  font-size: 0.9rem;
  color: var(--gray-800);
  margin-bottom: 0.5rem;
}

.subServicesList {
  list-style-type: none;
  padding-left: 1rem;
}

.subServicesList li {
  font-size: 0.9rem;
  color: var(--gray-800);
  margin-bottom: 0.25rem;
}

.detailItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.detailLabel {
  font-weight: bold;
  color: var(--gray-800);
}

.detailValue {
  color: var(--brand-color);
}

.afterHours {
  background-color: var(--highlight-yellow);
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-weight: bold;
}

.afterHoursLabel {
  color: var(--brand-color);
  font-weight: bold;
  margin-left: 0.5rem;
}

.errorMessage {
  color: #ef4444;
  font-size: 0.875rem;
  margin-top: 1rem;
  padding: 0.5rem;
  background-color: #fee2e2;
  border-radius: 4px;
  text-align: center;
}

.navigationButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.primaryButton {
  background-color: var(--primary-green);
  color: var(--white);
  border: none;
}

.primaryButton:hover {
  background-color: var(--hover-green);
}

.primaryButton:disabled {
  background-color: var(--gray-400);
  cursor: not-allowed;
}