/* Footer styles */
.footer {
  background-color: #041c43;
  padding: 2.5rem 0;
  color: #ffffff;
  font-size: 0.9rem;
  position: relative;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
}

.footerSection h3 {
  font-size: 1.1rem;
  margin-bottom: 0.8rem;
  color: #ffffff;
}

.footerSection ul {
  list-style-type: none;
  padding: 0;
}

.footerSection ul li {
  margin-bottom: 0.4rem;
}

.footerSection ul li a {
  color: #ffffff;
  text-decoration: none;
  transition: opacity 0.3s ease;
}

.footerSection ul li a:hover {
  opacity: 0.8;
}

.socialIcons {
  display: flex;
  gap: 0.8rem;
  margin-top: 0.8rem;
}

.socialIcons a {
  color: #ffffff;
  font-size: 1.3rem;
  transition: opacity 0.3s ease;
}

.socialIcons a:hover {
  opacity: 0.8;
}

.iconMargin {
  margin-right: 0.5rem;
}

.newsletterSection {
  margin-top: 2rem;
  text-align: center;
}

.subscribeButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: #041c43;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.subscribeButton:hover {
  background-color: #f0f0f0;
  transform: translateY(-2px);
}

.subscribeButton:active {
  transform: translateY(0);
}

.envelopeIcon {
  margin-right: 8px;
}

.bookNowSection {
  margin-top: 2rem;
  text-align: center;
}

.bookNowButton {
  display: inline-block;
  background-color: #007bff;
  color: #ffffff;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.bookNowButton:hover {
  background-color: #0056b3;
}

.bottomBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.abn {
  text-align: left;
}

.copyrightText {
  text-align: right;
}

.backToTopButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
  z-index: 1000;
}

.backToTopButton:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.backToTopButton:active {
  transform: translateY(0);
}

@media (max-width: 768px) {
  .footer {
    padding: 2rem 0;
  }

  .grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  
  .footerSection {
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .footerSection h3 {
    font-size: 1rem;
    margin-bottom: 0.6rem;
  }

  .footerSection ul li {
    margin-bottom: 0.3rem;
  }
  
  .socialIcons {
    justify-content: center;
  }

  .newsletterSection,
  .bookNowSection {
    margin-top: 1.5rem;
  }

  .subscribeButton,
  .bookNowButton {
    width: 100%;
    max-width: 250px;
  }

  .bottomBar {
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;
    padding-top: 0.8rem;
  }

  .abn,
  .copyrightText {
    text-align: center;
    margin-bottom: 0.4rem;
    font-size: 0.8rem;
  }

  .backToTopButton {
    bottom: 15px;
    right: 15px;
    width: 35px;
    height: 35px;
    font-size: 1rem;
  }
}
