.sectionTitle {
  font-size: 2.5rem;
  color: #333;
  text-align: center;
  margin-bottom: 3rem;
  font-weight: 700;
}

@media (max-width: 768px) {
  .sectionTitle {
    font-size: 2rem;
  }
}
