.serviceCard {
  display: flex;
  margin-bottom: 4rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  min-height: 400px; /* Increase the height of each card */
}

.reversed {
  flex-direction: row-reverse;
}

.textContent {
  flex: 1;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.imageContent {
  flex: 1;
}

.title {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #333;
}

.description {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 1.5rem;
}

.learnMoreButton {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: #4CAF50;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  font-size: 1.1rem;
}

.learnMoreButton:hover {
  background-color: #45a049;
}

@media (max-width: 768px) {
  .serviceCard {
    flex-direction: column;
  }

  .reversed {
    flex-direction: column;
  }

  .textContent {
    padding: 1.5rem;
  }

  .title {
    font-size: 1.75rem;
  }

  .description {
    font-size: 1rem;
  }

  .learnMoreButton {
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }
}
.serviceCard {
  position: relative;
  width: 100%;
  height: 400px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.title {
  font-size: 24px;
  margin-bottom: 10px;
}

.description {
  font-size: 16px;
  margin-bottom: 15px;
}

.featureList {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}

.featureItem {
  margin-bottom: 5px;
}

.checkmark {
  color: #4CAF50;
  margin-right: 5px;
}

.buttonContainer {
  display: flex;
  gap: 10px;
}

.learnMoreButton,
.bookNowButton {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s;
}

.learnMoreButton {
  background-color: transparent;
  border: 2px solid white;
  color: white;
}

.bookNowButton {
  background-color: #4CAF50;
  color: white;
}

.learnMoreButton:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.bookNowButton:hover {
  background-color: #45a049;
}
.serviceCard {
  display: flex;
  margin-bottom: 4rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: #ffffff;
  min-height: 400px;
}

.serviceCard:hover {
  transform: translateY(-8px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
}

.reversed {
  flex-direction: row-reverse;
}

.imageSection,
.contentSection {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.imageSection {
  position: relative;
  overflow: hidden;
}

.imageWrapper {
  position: relative;
  padding-top: 75%; /* 4:3 aspect ratio */
}

.cardImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.serviceCard:hover .cardImage {
  transform: scale(1.1);
}

.contentSection {
  padding: 2rem;
  justify-content: space-between;
  background-color: #ffffff;
}

.category {
  font-size: 0.9rem;
  color: #4CAF50;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  font-weight: bold;
  letter-spacing: 1px;
}

.title {
  font-size: 1.75rem;
  margin-bottom: 1rem;
  color: #333;
  font-weight: 700;
}

.description {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: #555;
  line-height: 1.6;
}

.featureList {
  list-style-type: none;
  padding: 0;
  margin-bottom: 1.5rem;
}

.featureItem {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.75rem;
  font-size: 0.9rem;
  color: #444;
}

.checkmark {
  color: #4CAF50;
  margin-right: 0.5rem;
  font-size: 1rem;
  flex-shrink: 0;
  margin-top: 0.2rem;
}

.featureText {
  flex: 1;
}

.featureHighlight {
  color: #4CAF50;
  font-weight: 600;
}

.buttonContainer {
  display: flex;
  gap: 1rem;
  margin-top: auto;
}

.learnMoreButton,
.bookNowButton {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;
  flex: 1;
  text-align: center;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.learnMoreButton {
  background-color: transparent;
  border: 2px solid #4CAF50;
  color: #4CAF50;
}

.learnMoreButton:hover {
  background-color: #4CAF50;
  color: white;
}

.bookNowButton {
  background-color: #4CAF50;
  color: white;
  border: none;
}

.bookNowButton:hover {
  background-color: #45a049;
}

.buttonIcon {
  margin-left: 0.5rem;
  transition: transform 0.3s ease;
}

.learnMoreButton:hover .buttonIcon {
  transform: translateX(5px);
}

.sliderArrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  padding: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sliderArrow:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

@media (max-width: 1200px) {
  .serviceCard {
    flex-direction: column;
  }

  .reversed {
    flex-direction: column;
  }

  .imageSection {
    height: 300px;
  }

  .imageWrapper {
    padding-top: 60%; /* 5:3 aspect ratio */
  }

  .contentSection {
    padding: 2rem;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 1.5rem;
  }

  .description {
    font-size: 0.9rem;
  }

  .buttonContainer {
    flex-direction: column;
  }

  .learnMoreButton,
  .bookNowButton {
    width: 100%;
    margin-bottom: 0.75rem;
  }
}

@media (max-width: 480px) {
  .imageSection {
    height: 200px;
  }

  .contentSection {
    padding: 1.5rem;
  }

  .title {
    font-size: 1.25rem;
  }
}
.serviceCard {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: #ffffff;
  width: 100%;
  max-width: 500px;
  height: 650px;
}

.serviceCard:hover {
  transform: translateY(-8px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.serviceCard:focus-within {
  outline: 3px solid #4CAF50;
}

.imageSection {
  position: relative;
  overflow: hidden;
  height: 250px;
}

.carousel {
  position: relative;
  width: 100%;
  height: 100%;
}

.cardImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

@media (max-width: 767px) {
  .imageSection {
    height: 200px;
  }

  .carousel {
    height: 100%;
  }

  .cardImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.serviceCard:hover .cardImage {
  transform: scale(1.05);
}

.carouselControls {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.carouselButton {
  background-color: rgba(255, 255, 255, 0.7);
  color: #333;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1.2rem;
}

.carouselButton:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

.contentSection {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.contentHeader {
  margin-bottom: 1rem;
}

.category {
  font-size: 0.8rem;
  color: #4CAF50;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  font-weight: bold;
  letter-spacing: 1px;
}

.title {
  font-size: 1.5rem;
  color: #333;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  transition: color 0.3s ease;
}

.titleIcon {
  margin-right: 0.5rem;
  font-size: 1.25rem;
  color: #4CAF50;
  transition: transform 0.3s ease;
}

.serviceCard:hover .title {
  color: #4CAF50;
}

.serviceCard:hover .titleIcon {
  transform: scale(1.1);
}

.description {
  font-size: 0.9rem;
  margin-bottom: 1rem;
  color: #555;
  line-height: 1.6;
}

.featureList {
  list-style-type: none;
  padding: 0;
  margin-bottom: 1rem;
}

.featureItem {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: #444;
  margin-bottom: 0.5rem;
}

.checkmark {
  color: #4CAF50;
  margin-right: 0.5rem;
  font-size: 0.9rem;
}

.buttonContainer {
  display: flex;
  gap: 0.5rem;
  margin-top: auto;
}

.button {
  padding: 0.75rem 1rem;
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;
  text-align: center;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.primaryButton {
  background-color: #4CAF50;
  color: white;
  border: none;
}

.primaryButton:hover {
  background-color: #45a049;
}

.secondaryButton {
  background-color: transparent;
  border: 2px solid #4CAF50;
  color: #4CAF50;
}

.secondaryButton:hover {
  background-color: #4CAF50;
  color: white;
}

.buttonIcon {
  margin-left: 0.5rem;
  transition: transform 0.3s ease;
}

.button:hover .buttonIcon {
  transform: translateX(5px);
}

.button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.5);
}

/* Color coding for different service types */
.residential {
  border-top: 5px solid #4CAF50;
}

.commercial {
  border-top: 5px solid #2196F3;
}

.speciality {
  border-top: 5px solid #FFC107;
}

@media (max-width: 767px) {
  .serviceCard {
    max-width: 100%;
    height: auto;
  }

  .imageSection {
    height: 200px;
  }

  .carousel {
    height: 100%;
  }

  .cardImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .contentSection {
    padding: 1rem;
  }

  .title {
    font-size: 1.25rem;
  }

  .description {
    font-size: 0.8rem;
  }

  .featureItem {
    font-size: 0.75rem;
  }

  .buttonContainer {
    flex-direction: column;
  }

  .button {
    width: 100%;
    font-size: 0.8rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .serviceCard {
    max-width: 450px;
    height: 600px;
  }
}

@media (min-width: 1024px) {
  .serviceCard {
    max-width: 600px;
    height: 700px;
  }

  .imageSection {
    height: 250px;
  }

  .title {
    font-size: 1.5rem;
  }

  .description {
    font-size: 1rem;
  }

  .featureItem {
    font-size: 0.9rem;
  }

  .button {
    font-size: 1rem;
  }
}
