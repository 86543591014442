.faqSection {
  width: 100%;
  background-color: var(--gray-100);
  padding: 60px 0;
}

.faqContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

.faqList {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.faqItem {
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  overflow: hidden;
}

.faqQuestion {
  width: 100%;
  text-align: left;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.faqQuestion:hover,
.faqQuestion.open {
  background-color: #f3f4f6;
}

.faqQuestion:focus {
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
}

.questionText {
  font-size: 1rem;
  font-weight: 600;
  color: #111827;
}

.faqIcon {
  color: #6b7280;
}

.faqAnswer {
  background-color: #ffffff;
  overflow: hidden;
}

.faqAnswerContent {
  padding: 16px;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.faqAnswer[style*="height: 0px"] .faqAnswerContent {
  opacity: 0;
}

.faqAnswerContent p {
  margin: 0;
  color: #4b5563;
  line-height: 1.5;
}

@media (max-width: 640px) {
  .faqSection {
    padding: 40px 0;
  }

  .faqContainer {
    padding: 0 16px;
  }

  .faqQuestion,
  .faqAnswerContent {
    padding: 12px;
  }
}