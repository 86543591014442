:root {
  --brand-color: #021c43;
  --primary-green: #22c55e;
  --hover-green: #16a34a;
  --light-green: #ddffed;
  --white: #ffffff;
  --gray-100: #f9fafb;
  --gray-200: #e5e7eb;
  --gray-300: #d1d5db;
  --gray-400: #9ca3af;
  --gray-800: #1f2937;
}

.calendarSection {
  background-color: var(--white);
  margin-bottom: 2rem;
}

.calendarSection :global(.react-calendar) {
  width: 100%;
  max-width: none;
  font-size: 1.1em;
  border: none;
}

.calendarSection :global(.react-calendar__tile) {
  padding: 1.5em 0.5em;
}

.timeAndFrequencySection {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
}

.timeSection,
.frequencySection {
  flex: 1;
}

.sectionTitle {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--gray-800);
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.sectionTitle svg {
  margin-right: 0.5rem;
  color: var(--primary-green);
}

/* Time Selection Container */
.timeSelectionContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Time Grid Styles */
.timeGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.75rem;
}

.timeGrid.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.timeSlot {
  background-color: var(--gray-100);
  border: 1px solid var(--gray-200);
  border-radius: 0.25rem;
  padding: 0.75rem;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.timeSlot:hover {
  background-color: var(--light-green);
  border-color: var(--primary-green);
}

.timeSlot.selected {
  background-color: var(--primary-green);
  color: var(--white);
  border-color: var(--primary-green);
}

/* After Hours Styles */
.afterHoursSection {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.afterHoursButton {
  width: 100%;
  background-color: var(--gray-100);
  border: 1px solid var(--gray-200);
  border-radius: 0.25rem;
  padding: 0.75rem;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.afterHoursButton:hover {
  background-color: var(--light-green);
  border-color: var(--primary-green);
}

.afterHoursButton.selected {
  background-color: var(--primary-green);
  color: var(--white);
  border-color: var(--primary-green);
}

.afterHoursInput {
  width: 100%;
  padding: 0.75rem;
  font-size: 0.9rem;
  border: 1px solid var(--gray-200);
  border-radius: 0.25rem;
  background-color: var(--white);
  transition: all 0.3s ease;
}

.afterHoursInput:focus {
  outline: none;
  border-color: var(--primary-green);
  box-shadow: 0 0 0 2px var(--light-green);
}

/* Frequency Options Styles */
.frequencyOptions {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}

.frequencyButton {
  background-color: var(--gray-100);
  border: 1px solid var(--gray-200);
  border-radius: 0.25rem;
  padding: 0.75rem 1rem;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  flex-grow: 1;
  text-align: center;
}

.frequencyButton:hover {
  background-color: var(--light-green);
  border-color: var(--primary-green);
}

.frequencyButton.selected {
  background-color: var(--primary-green);
  color: var(--white);
  border-color: var(--primary-green);
}

.errorMessage {
  color: #f87171;
  font-size: 0.875rem;
  margin-top: 0.75rem;
  padding: 0.5rem;
  background-color: #fee2e2;
  border-radius: 0.25rem;
  text-align: center;
}

@media (max-width: 768px) {
  .timeAndFrequencySection {
    flex-direction: column;
  }

  .timeGrid {
    grid-template-columns: repeat(4, 1fr);
  }

  .frequencyButton {
    flex-basis: calc(50% - 0.375rem);
  }
}

@media (max-width: 480px) {
  .timeGrid {
    grid-template-columns: repeat(2, 1fr);
  }

  .calendarSection :global(.react-calendar__tile) {
    padding: 1em 0.5em;
  }
}